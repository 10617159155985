/**
 * FILE: jquery.ptTileSelect.css
 * 	Default style for the timeselect container.
 * 
 *  LAST UPDATED:
 * 
 * 		- $Date: 2009/04/12 20:23:02 $
 * 		- $Author: paulinho4u $
 * 		- $Revision: 1.1 $
 */


#ptTimeSelectCntr {
  display: none;
  font-size: 12px;
  margin-left: 0;
  position: absolute;
  width: 270px !important;
  z-index: 10;
}




#ptTimeSelectCntr .ui-widget-content {
  background: #fff none repeat scroll 0 0;
  border: medium none !important;
  border-radius: 0;
  margin-bottom: 0px;
  margin-top: 0 !important;
  padding: 5px;
}
#ptTimeSelectCntr .ui-widget.ui-widget-content {
  border: 1px solid #ddd !important;
}
#ptTimeSelectCntr .ui-widget{}
#ptTimeSelectCntr .ui-widget-header {
  background: #151515 none repeat scroll 0 0;
  border: 2px solid #252525;
  border-radius: 0;
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  height: 40px;
  padding: 5px;
}
#ptTimeSelectCntr #ptTimeSelectUserTime {
  font-size: larger;
  line-height: 26px;
  padding: 0 10px;
  text-align: center;
}
#ptTimeSelectCntr .ui-widget-header .ui-icon {
  background-image: url("images/ui-icons_444444_256x240.png") !importent;
}




#ptTimeSelectCntr #ptTimeSelectCloseCntr {
  display: block;
  padding: 0;
}






#ptTimeSelectCntr .ui-widget.ui-widget-content {
	margin-top: 0;
}
#ptTimeSelectCntr .ptTimeSelectLeftPane.ui-widget-content {
	border-top:none;
	border-bottom:none;
	border-left:none;
	border-right-width: 2px;
}
#ptTimeSelectCntr .ptTimeSelectRightPane.ui-widget-content {
	border: none;
}




/*  content style and  hover style */
#ptTimeSelectCntr .ptTimeSelectHrCntr a, 
#ptTimeSelectCntr .ptTimeSelectMinCntr a {
  display: block;
  float: left;
  line-height: 32px;
  margin: 2px;
  padding: 0;
  text-align: center;
  text-decoration: none;
  transition: all 500ms ease;
}
#ptTimeSelectCntr .ptTimeSelectHrCntr a:hover, 
#ptTimeSelectCntr .ptTimeSelectMinCntr a:hover{
  background: #43c3ea !important;
  border-color: #43c3ea !important;
}



/*  Hour min text style */
#ptTimeSelectCntr .ptTimeSelectTimeLabelsCntr {
  color: #252525;
  font-size: 13px;
  font-weight: 700;
}


/*  Cloase button style */
#ptTimeSelectCntr #ptTimeSelectCloseCntr a {
  border-radius: 30%;
  display: block;
  height: 25px !important;
  line-height: 24px;
  margin: 0 !important;
  opacity: 1;
  padding: 0 !important;
  text-align: center;
  width: 25px !important;
  padding: 3px 4px !important;
}

/*  am pm content style, hover bg */
#ptTimeSelectCntr .ui-state-default {
  background: #fff !important;
  border: 1px solid #eaeaea !important;
  border-radius: 0;
  color: #252525 !important;
  font-size: 12px;
  font-weight: normal;
  height: 34px;
  margin-bottom: 5px;
  outline: medium none;
  text-align: center;
  width: 34px !important;
  line-height: 33px;
  margin: 2px;
  transition: all 500ms ease;
}
#ptTimeSelectCntr .ui-state-default:hover{
  background: #43c3ea !important; 
  border-color: #43c3ea !important; 
}


/*  For time set button css */
#ptTimeSelectCntr #ptTimeSelectSetButton {
  padding: 0 15px 5px 0;
}
#ptTimeSelectSetButton .ui-state-hover{
  background: #d5ac63 !important;
  border: 2px solid #252525 !important;
  border-radius: 0;
  color: #252525 !important;
  font-size: 12px !important;
  font-weight: normal;
  height: 35px;  
  width: 70px !important;
}
#ptTimeSelectCntr #ptTimeSelectSetButton a {
  display: block;
  text-align: center;
  float: right;
  background: #252525 !important;
  border: 2px solid #252525 !important;
  border-radius: 0;
  color: #fff !important;
  font-size: 12px !important;
  font-weight: normal;
  height: 35px;  
  width: 70px !important;
  padding: 0;
  margin: 0;
  line-height: 32px;
  text-transform: uppercase;
  transition: all 500ms ease;
}
#ptTimeSelectCntr #ptTimeSelectSetButton a:hover{
  background: #43c3ea !important;  
} 






